//-----------------------------------------------------------------------------------------------------------
// copy/paste snippet for SPAs to change document.domain
// Preferred version with location.search parameter for the commonDomain
// Please execute this before any application JavaScript.
//-----------------------------------------------------------------------------------------------------------
(function() {
  var urlParameterName = 'omCommonDomain';
  function getParameter(paramName) {
    var searchString = window.location.search.substring(1);
    var params = searchString.split("&");
    var entry;
    for (var i=0; i<params.length; i++) {
      entry = params[i].split("=");
      if (entry[0] === paramName) {
        return entry[1];
      }
    }
    return null;
  }
  // change document.domain
  try {
    console.log("search", window.location.search)
    if (window.location.search.indexOf(urlParameterName) > -1) {
      var commonDomain = getParameter(urlParameterName);
      console.log("commonDomain", commonDomain)
      if (commonDomain) {
        document.domain = commonDomain;
        console.log("document.domain", document.domain)
      }
    }
  } catch (e) {
    console.warn('Can not change document.domain', e);
  }
})();
//-----------------------------------------------------------------------------------------------------------
// copy/paste snippet to integrate adapted ow-tracking-api.js for adobe analytics
// Please execute this after integration of jquery
//-----------------------------------------------------------------------------------------------------------
(function() {
  var urlParameterName = 'omCommonDomain';
  var urlOwTrackintApi = '/etc.clientlibs/onemarketing/azde/aem-core/clientlibs/aem-core/ow-tracking-api.js';
  function addOwTrackingApi() {
    var referrerProtocolHost = document.referrer.split('/').slice(0,3).join('/');
    var scriptTag = document.createElement('script');
    scriptTag.setAttribute('type', 'text/javascript');
    scriptTag.setAttribute('src', referrerProtocolHost + urlOwTrackintApi);
    document.head.appendChild(scriptTag);
  }
  // change document.domain
  try {
    if (window.location.search.indexOf(urlParameterName) > -1) {
      addOwTrackingApi();
    }
  } catch (e) {
    console.warn('Can not add adapted ow-tracking-api.js', e);
  }
})();
